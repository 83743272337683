import React, { Component } from 'react';

import { BrowserRouter , Routes, Route, Navigate } from "react-router-dom";

import './scss/style.scss';

const AcercaDe = React.lazy(() => import('./pages/es/aboutus/AboutUs'));
const Inicio = React.lazy(() => import('./pages/es/home/Home'));
const Privacidad = React.lazy(() => import('./pages/es/privacy/Privacy'));
const Terminos = React.lazy(() => import('./pages/es/terms/Terms'));
const ComoUsar = React.lazy(() => import('./pages/es/howuse/HowUse'));

const AboutUs = React.lazy(() => import('./pages/en/aboutus/AboutUs'));
const Home = React.lazy(() => import('./pages/en/home/Home'));
const Privacy = React.lazy(() => import('./pages/en/privacy/Privacy'));
const Terms = React.lazy(() => import('./pages/en/terms/Terms'));
const HowUse = React.lazy(() => import('./pages/en/howuse/HowUse'));

class App extends Component {


  render() {
    return (
      <BrowserRouter>
        <Routes>
          {/* Redirección basada en el idioma del navegador */}
          <Route
            path="/"
            element={
              <Navigate
                to={navigator.language.startsWith('es') ? '/es/' : '/en/'}
                replace
              />
            }
          />

          {/* Rutas en español */}
          <Route path="/es/" element={<Inicio />} />
          <Route path="/es/aboutus" element={<AcercaDe />} />
          <Route path="/es/privacy" element={<Privacidad />} />
          <Route path="/es/terms" element={<Terminos />} />
          <Route path="/es/howuse" element={<ComoUsar />} />

          {/* Rutas en inglés */}
          <Route path="/en/" element={<Home />} />
          <Route path="/en/aboutus" element={<AboutUs />} />
          <Route path="/en/privacy" element={<Privacy />} />
          <Route path="/en/terms" element={<Terms />} />
          <Route path="/en/howuse" element={<HowUse />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
